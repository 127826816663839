import React from 'react';

import { graphql } from 'gatsby';
import DocsLayout from '../../components/ui/DocsLayout';

const Developers = () => (
  <DocsLayout>
    <h1>Developers</h1>
    <h2>Overview</h2>
    <p>
      The SpotCast website is developed with a static website and app generator
      called <a href="https://gatsbyjs.org">Gatsby</a>. Gatsby is based
      on&nbsp;React which is developed by the team at Facebook.
    </p>
    <p>
      <strong>Static Website Generator?</strong>
    </p>
    <p>
      Basically traditional websites send a request from the&nbsp;user's browser
      to a server where the website is compiled and then send back to the user's
      browser. A static website pre complies the website and shows the user a
      pre-made website from a CDN. The result is a massive time saving on the
      user side.
    </p>
    <p>
      Gatsby is unique in that once the static site is shown to the user, it
      turns into a react app and can do everything a "normal" database driven
      site can do.
    </p>
    <h2>Required knowledge</h2>
    <ul>
      <li>HTML</li>
      <li>CSS</li>
      <li>JavaScript</li>
      <li>React</li>
      <li>JSX</li>
      <li>GraphQL (for API work)&nbsp;</li>
    </ul>
    <h2>Infrastructure</h2>
    <ul>
      <li>Github for repository&nbsp;hosting</li>
      <li>
        <a href="https://www.netlify.com/">Netlify</a>
        &nbsp;for continuous&nbsp;development and "hosting"
      </li>

      <li>Google Tag Manager/Google Analytics</li>
    </ul>
    <h2>Development workflow</h2>
    <ol>
      <li>
        Developer clones our GitHub&nbsp;repository <em>*Add in </em>
        link
        <em> and make private</em>
      </li>
      <li>
        Developer runs and NPM install and runs gatsby build, which provides as
        a local dev environment with hot reloading
      </li>
      <li>
        Changes to the website are made on a branch of the GitHub repository.
      </li>
      <li>
        On pull request, Netlify, which is integrated with GitHub, will create a
        test build on a development URL
      </li>
      <li>
        Assuming the build completes and the website has physically tested okay,
        the branch can be merged and the actual website will be built and
        published.
      </li>
    </ol>
    <h2>Dependencies</h2>
    <p>
      There are a bunch of Gatsby based plugins to expend functionality which
      are pretty self-explanatory. In addition,&nbsp;these libraries are used
    </p>
    <ul>
      <li>
        <a href="https://chakra-ui.com/">
          Chakra UI
          <strong> -&nbsp;</strong>
        </a>
        For component-based CSS&nbsp;
      </li>
      <li>
        <a href="https://styled-system.com/">Styled System for rapid UI dev</a>
      </li>
    </ul>
    <h2 id="linting">Eslint/prettier etc</h2>
    <p>Our prefered editior is VS code</p>
    <p>
      In order to make the code more readable and maintainable, we use eslint
      and prettier. This is setup at a package level and uses wesbos's config
      https://github.com/wesbos/eslint-config-wesbos Please see [this section of
      his docs for config of VS
      code](https://github.com/wesbos/eslint-config-wesbos#with-vs-code)
    </p>
    <h2 id="hosting">Hosting</h2>
    <p>
      The SpotCast website is a a little bit different in that, there is not a
      traditional server running. Instead our website is a static website and
      when we make changes, either when the code or content has been changed,
      the site is rebuilt. This build process creates all of the webpages and
      then puts them on a CDN rather than a traditional server. Because the
      website is highly distributed, it's unlikely that all CDN's in the world
      could go down resulting in high uptimes
    </p>
    <h3>What can go wrong?</h3>
    <p>
      Not much on the server side. But here is a list of problems, outcomes and
      solutions
    </p>
    <ul>
      <li>
        Coding error: 99% of the time the build fails, result is that the most
        recent version of the site is not over written and continues to run
        normally. Solution is to check build logs and fix code.
      </li>
      <li>
        DatoCMS build fails: Likely cause is that data got deleted or was not
        correctly filled out. Result is as above. Solution is to check build
        logs and fix code.
      </li>
    </ul>
    <h3>Site down?</h3>
    <p>
      In the event that our website is down, the first thing is to check the{' '}
      <a href="https://www.netlifystatus.com/">Netlify status page</a>. If the
      issue is a CDN issue then it's likely not just us and the issue will be
      resolved shortly.
    </p>
    <p>
      Otherwise,{' '}
      <a href="https://www.netlify.com/support/">
        Contact support via the form
      </a>
    </p>
    <p>
      Aside from Netlify, other failure points include. Cloudflare or DNS
      provider and our registrar{' '}
    </p>
  </DocsLayout>
);

export default Developers;

export const pageQuery = graphql`
  query {
    allComponentMetadata {
      edges {
        node {
          id
          displayName
        }
      }
    }
  }
`;
